.vessel-card {
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 666px) {
  .vessel-card {
    width: 100%;
  }
}
